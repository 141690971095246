





























































































































































































































































































import '@webcomponents/webcomponentsjs/webcomponents-bundle';
import Default from '@/main/Default.vue';
import { Component, Watch } from 'vue-property-decorator';
import WdSalesWidgetInterface from '@/interfaces/WdSalesWidget';

import VueSlider from 'vue-slider-component';

import SubmitButton from '@/components/basic/SubmitButton.vue';
import Person from '@/components/Person.vue';
import House from '@/components/House.vue';

@Component({
  props: {
    defaultCommodity: String
  },
  components: {
    House,
    Person,
    VueSlider,
    SubmitButton
  }
})
export default class Prego extends Default implements WdSalesWidgetInterface {
  [x: string]: any;
  protected usageList: number[] = [1500, 2500, 3500, 4250, 5000];
  protected squareMetersList: Array<{ squareMeters: number; kWh: number }> = [
    { squareMeters: 50, kWh: 5000 },
    { squareMeters: 100, kWh: 12000 },
    { squareMeters: 150, kWh: 18000 },
    { squareMeters: 180, kWh: 20000 }
  ];
  protected usage: number | null = this.usageList[1];
  protected squareMeters: number | null = this.squareMetersList[0].squareMeters;
  protected heatingSelected: any;

  public created(): void {
    if (this.$props.defaultCommodity) {
      this.commodity = this.$props.defaultCommodity;
    }
  }

  get formattedUsage(): string {
    return this.formattedNumber(this.usage ?? 0);
  }

  set formattedUsage(newValue: string) {
    this.usage = Math.random(); // reset to update getter
    this.usage = parseInt(newValue.replace(/\D+/g, ''), 10);
  }

  get formattedUsageNt(): string {
    return this.formattedNumber(this.usageNt ?? 0);
  }

  set formattedUsageNt(newValue: string) {
    this.usageNt = Math.random(); // reset to update getter
    this.usageNt = parseInt(newValue.replace(/\D+/g, ''), 10);
  }

  get formattedSquareMetes(): number | null {
    return this.squareMeters;
  }

  set formattedSquareMetes(newValue: number | null) {
    this.squareMeters = Math.random(); // reset to update getter
    this.squareMeters = newValue && newValue > 0 ? parseInt(newValue.toString().replace(/\D+/g, ''), 10) : null;
  }

  @Watch('commodity')
  protected onCommodityChange(newValue: string): void {
    if (newValue === 'electricity') {
      this.usage = this.usageList[1];
    }
    if (newValue === 'gas') {
      this.setKWhFromSquareMeters(this.squareMeters ?? 0);
    }
    if (newValue === 'heating') {
      this.heatingSelected = this.heatingOptions[0];
      this.consumptionType = this.heatingOptions[0].consumptionType;
    }
  }

  @Watch('squareMeters')
  protected onSquareMetersChange(newValue: number): void {
    this.setKWhFromSquareMeters(newValue);
  }

  protected setKWhFromSquareMeters(squareMeters: number): void {
    let i = 0;
    do {
      if (!squareMeters) {
        this.usage = null;
        break;
      } else if (squareMeters < this.squareMetersList[i].squareMeters) {
        this.usage = (this.squareMetersList[i].kWh / this.squareMetersList[i].squareMeters) * squareMeters;
        break;
      } else if (
        squareMeters >= this.squareMetersList[i].squareMeters &&
        this.squareMetersList[i + 1] &&
        squareMeters < this.squareMetersList[i + 1].squareMeters
      ) {
        const kWhPerSquareMeter =
          (this.squareMetersList[i + 1].kWh - this.squareMetersList[i].kWh) /
          (this.squareMetersList[i + 1].squareMeters - this.squareMetersList[i].squareMeters);
        this.usage =
          this.squareMetersList[i].kWh +
          parseInt((kWhPerSquareMeter * (squareMeters - this.squareMetersList[i].squareMeters)).toString(), 10);
        break;
      } else if (i >= this.squareMetersList.length - 1 && squareMeters >= this.squareMetersList[i].squareMeters) {
        const kWhPerSquareMeter =
          (this.squareMetersList[i].kWh - this.squareMetersList[i - 1].kWh) /
          (this.squareMetersList[i].squareMeters - this.squareMetersList[i - 1].squareMeters);
        this.usage =
          this.squareMetersList[i].kWh +
          parseInt((kWhPerSquareMeter * (squareMeters - this.squareMetersList[i].squareMeters)).toString(), 10);
        break;
      }
      i++;
    } while (squareMeters > this.squareMetersList[i].squareMeters || i < this.squareMetersList.length);
  }

  protected formattedNumber(value: number): string {
    return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '';
  }

  protected incrementUsage(): void {
    if (!this.usage || this.usage < this.usageList[0]) {
      this.usage = this.usageList[0];
    } else if (this.usage < this.usageList[1]) {
      this.usage = this.usageList[1];
    } else if (this.usage < this.usageList[2]) {
      this.usage = this.usageList[2];
    } else if (this.usage < this.usageList[3]) {
      this.usage = this.usageList[3];
    } else {
      this.usage = this.usageList[4];
    }
  }

  protected decrementUsage(): void {
    if (!this.usage || this.usage <= this.usageList[1]) {
      this.usage = this.usageList[0];
    } else if (this.usage <= this.usageList[2]) {
      this.usage = this.usageList[1];
    } else if (this.usage <= this.usageList[3]) {
      this.usage = this.usageList[2];
    } else if (this.usage <= this.usageList[4]) {
      this.usage = this.usageList[3];
    } else {
      this.usage = this.usageList[4];
    }
  }
}
